.InputLabel {
	padding: 10px;
}

.InputLabel-Notebook {
	font-size: 30px;
}

.InputLabel-Pastel {
	font-size: 15px;
}
