.Header {
	display: grid;
}

.Header-Notebook {
}

.Header-Pastel {
	background-color: white;
	border-style: solid;
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
}
