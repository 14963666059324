.App {
	text-align: center;
	margin: 0;
	height: 100%;
}

.App-Notebook {
	font-family: "Babylonica";
	color: #4a2a0a;
}

.App-Pastel {
	font-family: "Bona Nova";
	color: #5f86d2;
	background-color: #f0ccba;
	opacity: 70%;
}
